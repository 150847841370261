import { IColorsMapProps, ICardHorizontalMapProps } from "./../interfaces";

// Colors

export const colorsArray: string[] = [
  "acapulco",
  "alabaster",
  "athens-gray",
  "black",
  "geyser",
  "gin",
  "gun-powder",
  "linen",
  "manhattan",
  "merino",
  "terracotta",
  "white",
];

export const colorsMap: IColorsMapProps[] = [
  { name: "acapulco", value: "#81b29a" },
  { name: "alabaster", value: "#fafafa" },
  { name: "athens-gray", value: "#ebebeb" },
  { name: "black", value: "#000" },
  { name: "geyser", value: "#dee2e6" },
  { name: "gin", value: "#e5efea" },
  { name: "gun-powder", value: "#3d405b" },
  { name: "linen", value: "#fbf1ee" },
  { name: "manhattan", value: "#e5efea" },
  { name: "merino", value: "#f4f1de" },
  { name: "terracotta", value: "#d85531" },
  { name: "white", value: "#fff" },
];

// Alternative information for unit's gallery
export const unitsImagesAlts: string[] = [
  "Interior de una cabina mostrando las dos camas matrimoniales y entrada del baño",
  "Interior de una cabina mostrando las dos camas matrimoniales y entrada principal",
];

// Alternative information for common spaces gallery
export const commonImagesAlts: string[] = [
  "Vista aérea de las Cabinas Vista Lago Arenal, Tilarán, Guanacaste",
  "Vista del Lago Arenal desde el frente de las Cabinas Vista Lago Arenal, Tilarán, Guanacaste",
  "Sol ocultándose detrás de las montañas y las torres eólicas al atardecer",
  "Vista del atardecer desde la parte frontal de las cabinas",
  "Vista de Oeste a Este de las instalaciones",
  "Corredores mostrando las paredes frontales de madera y sillas de madera frente al lago Arenal, Tilarán, Guanacaste",
  "Corredores de las cabinas mostrando las columnas de piedra laja y sillas de madera frente al lago Arenal, Tilarán, Guanacaste",
  "Entrada de la propiedad mostrando el lago Arenal, la parte trasera de las instalaciones y el parqueo",
  "Jardínes junto al parqueo con vista al Lago Arenal, Tilarán, Guanacaste",
  "Area de fregadero y cocina de gas de la cocina compartida",
  "Comedor para 6 personas de la cocina compartida",
];

export const mapFoodAndDrinks: ICardHorizontalMapProps[] = [
  {
    img: "/info/macadamia.png",
    facebook: "https://www.facebook.com/cafeymacadamia",
    map: "https://www.google.com/maps/@10.558976,-84.9985608,13z",
  },
  {
    img: "/info/lake-arenal-sea-food.jpg",
    facebook:
      "https://www.facebook.com/Lake-Arenal-Pizza-Seafood-114427243748639",
    map: "https://www.google.es/maps/dir/Vista+Lago+Arenal,+900+metros+norte+del+r%C3%ADo+Piedras,+Provincia+de+Guanacaste,+Tilar%C3%A1n/Lake+Arenal+Pizza+%26+Seafood,+142,+Provincia+de+Guanacaste/@10.5567609,-84.970804,17z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132!1m5!1m1!1s0x8f9fffdd6ea9cec1:0x4208b8500d1ee2e7!2m2!1d-84.9718438!2d10.5532761?hl=es",
  },
  {
    img: "/info/el-corral.jpg",
    facebook: "https://www.facebook.com/Restaurante-El-Corral-340370279728328",
    map: "https://www.google.es/maps/dir/Vista+Lago+Arenal,+Provincia+de+Guanacaste,+Tilar%C3%A1n,+Costa+Rica/El+Corral,+El+Aguacate,+El+Aguacate+Tierras+Morenas,+sobre+ruta+142,+Provincia+de+Guanacaste,+Tierras+Morenas,+Costa+Rica/@10.5599212,-84.9612751,15z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132!1m5!1m1!1s0x8fa0001e1ad77b23:0x7f83147d8e5325d3!2m2!1d-84.939673!2d10.5619977?hl=es",
  },
  {
    img: "/info/equus.jpg",
    facebook:
      "https://www.facebook.com/BarRestauranEquusYDiscotecPukaWaiku/?ref=page_internal",
    map: "https://www.google.es/maps/place/Equus/@10.5490798,-84.9805429,16z/data=!4m21!1m15!4m14!1m6!1m2!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2sVista+Lago+Arenal,+900+metros+norte+del+r%C3%ADo+Piedras,+Provincia+de+Guanacaste,+Tilar%C3%A1n!2m2!1d-84.9653107!2d10.5606132!1m6!1m2!1s0x8fa0005d781a534b:0xbe9f3f7e20a852b!2sG2X9%2BJQP+Equus,+Provincia+de+Guanacaste,+Naranjos+Agrios!2m2!1d-84.9805429!2d10.5490798!3m4!1s0x8fa0005d781a534b:0xbe9f3f7e20a852b!8m2!3d10.5490798!4d-84.9805429?hl=es",
  },
];

export const mapOcio: ICardHorizontalMapProps[] = [
  {
    img: "/info/tico-wind.jpg",
    facebook: "https://www.facebook.com/Tico-Wind-205818576114906",
    map: "https://www.google.es/maps/place/Tico+Wind+Windsurf,Kitesurf+and+SUP+Center/@10.5427607,-84.9704966,4218m/data=!3m1!1e3!4m21!1m15!4m14!1m6!1m2!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2sVista+Lago+Arenal,+Provincia+de+Guanacaste,+Tilar%C3%A1n!2m2!1d-84.9653107!2d10.5606132!1m6!1m2!1s0x8f9fffb123b07c13:0x1bbf6fc1b89175f!2sTico+Wind+Windsurf,Kitesurf+and+SUP+Center,+Route+142,+Guanacaste+Province!2m2!1d-84.9705062!2d10.5427527!3m4!1s0x8f9fffb123b07c13:0x1bbf6fc1b89175f!8m2!3d10.5427527!4d-84.9705062?hl=es",
  },
  {
    img: "/info/tejona.jpg",
    facebook: "https://www.facebook.com/Tico-Wind-205818576114906",
    map: "https://www.google.com/maps/dir/Vista+Lago+Arenal,+Provincia+de+Guanacaste,+Tilar%C3%A1n,+Costa+Rica/Plaza+de+deporte+Tejona,+Provincia+de+Guanacaste,+Ranchitos,+Costa+Rica/@10.5409282,-84.9958862,8435m/data=!3m2!1e3!4b1!4m13!4m12!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132!1m5!1m1!1s0x8f9fffb7737edc2b:0xe1cb45b91bcbf95b!2m2!1d-84.9908245!2d10.5228873",
  },
  {
    img: "/info/tucan-arenal.jpg",
    facebook:
      "https://www.facebook.com/Catamaran-Tucan-Arenal-1019870458064267/?ref=page_internal",
    map: "https://www.google.es/maps/dir/Vista+Lago+Arenal,+900+metros+norte+del+r%C3%ADo+Piedras,+Provincia+de+Guanacaste,+Tilar%C3%A1n/Catamar%C3%A1n+Tuc%C3%A1n+Arenal,+Puerto+San+Luis,+Provincia+de+Guanacaste,+Tilar%C3%A1n/@10.5054369,-85.0134921,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132!1m5!1m1!1s0x8fa001c5e56848fd:0x42140bc832537a54!2m2!1d-84.9359455!2d10.5093307?hl=es",
  },
  {
    img: "/info/waterfall-indio.jpeg",
    facebook: "https://www.facebook.com/cataratalapiedradelindio/",
    map: "https://www.google.es/maps/dir/Catarata+Piedra+del+indio+waterfall.tilaran,+145+Provincia+de+Guanacaste+San+miguel,+Quebrada+Grande-+Libano,+Tilar%C3%A1n/Cabinas+Vista+Lago+Arenal,+900+metros+norte+del+r%C3%ADo+Piedras,+Provincia+de+Guanacaste,+Tilar%C3%A1n,+50806/@10.486497,-85.0221805,12z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8fa003a7c63fcecf:0x957c0ca4ff970b35!2m2!1d-84.9278725!2d10.411834!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132?hl=es",
  },
  {
    img: "/info/waterfall-viento-fresco.jpg",
    facebook:
      "https://www.facebook.com/Viento-Fresco-Waterfalls-Restaurant-For-Nature-Lovers-Costa-Rica-181883601869131",
    map: "https://www.google.es/maps/dir/Vista+Lago+Arenal,+900+metros+norte+del+r%C3%ADo+Piedras,+Provincia+de+Guanacaste,+Tilar%C3%A1n/Viento+Fresco+Waterfall,+Provincia+de+Guanacaste,+Tilar%C3%A1n/@10.5054369,-85.0134921,12.21z/data=!4m13!4m12!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132!1m5!1m1!1s0x8fa002be128acb73:0x812880dee1ce4e77!2m2!1d-84.923783!2d10.4207721?hl=es",
  },
];

export const mapSupermarket: ICardHorizontalMapProps[] = [
  {
    img: "/info/zamora.jpg",
    map: "https://www.google.es/maps/dir/Cabinas+Vista+Lago+Arenal,+Provincia+de+Guanacaste,+Tilar%C3%A1n/H366%2BQ5G+Super+Market.zamora,+Provincia+de+Guanacaste,+Aguacate/@10.5598898,-84.9612191,15z/data=!3m1!4b1!4m13!4m12!1m5!1m1!1s0x8f9fff08e297a509:0xa18ae1cb8859035e!2m2!1d-84.9653107!2d10.5606132!1m5!1m1!1s0x8fa0014f3a41e82d:0xee121625563af95b!2m2!1d-84.9395572!2d10.5619368?authuser=0&hl=es",
  }
];
